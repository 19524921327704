import React, { useState } from "react";
import Read from "./Read";
import TabHome from "./TabHome";

interface HomeProps {}

interface HomeState {
  currentTab: number;
  tabIsTransitioning: boolean;
}

const Home = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const selectTabRead = () => {
    setCurrentTab(1);
  };

  const selectTabHome = () => {
    setCurrentTab(0);
  };

  const formatTabContainer = () => {
    switch (currentTab) {
      default:
        return <TabHome selectTabRead={selectTabRead} />;

      case 0:
        return <TabHome selectTabRead={selectTabRead} />;
      case 1:
        return <Read selectTabHome={selectTabHome} />;
    }
  };

  return <>{formatTabContainer()}</>;
};

export default Home;
