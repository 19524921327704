import React from "react";
import cover from "./image/cover.jpg";
import blunt from "./image/blunt.jpg";
import condensation from "./image/condensation.jpg";
import coverback from "./image/cover-back.jpg";
import spread1 from "./image/elec-sun-spread1.jpg";
import spread2 from "./image/elec-sun-spread2.jpg";
import gravity from "./image/gravity.jpg";
import haunt from "./image/haunt.jpg";
import hyperstranger from "./image/hyperstranger.jpg";
import parannoul from "./image/parannoul.jpg";
import planettelex from "./image/planet telex.jpg";
import rainsong from "./image/rain song.jpg";
import rgb from "./image/rgb.jpg";
import ripple from "./image/ripple.jpg";
import soak from "./image/soak.jpg";
import subterranean from "./image/subterranean.jpg";
import thankstophysics from "./image/thanks to physics.jpg";
import thesamethings from "./image/the same things often enough.jpg";
import thismoment from "./image/this moment.jpg";
import veils from "./image/veils.jpg";
import walking from "./image/walking in the countryside.jpg";
import wired from "./image/wired.jpg";
import witness from "./image/witness.jpg";
import yrb from "./image/yellow river.jpg";
import ysl from "./image/yellow street light.jpg";

import endvideo from "./image/electronic-sun.mp4";

const pages = [
  {
    id: -2,
    isImage: false,
    text: (
      <div id="_idContainer001" className="_idGenObjectStyleOverride-1"></div>
    ),
  },
  {
    id: -1,
    isImage: true,
    image: (
      <div id="_idContainer001" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={cover} alt="" />
      </div>
    ),
  },

  {
    id: 0,
    isImage: true,
    image: (
      <div id="_idContainer001" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={rgb} alt="" />
      </div>
    ),
  },
  {
    id: 1,
    title: "RGB",
    isImage: false,
    text: (
      <div id="_idContainer002" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">RGB</span>
        </p>
        <p> hundreds of thousands of tiny pixels in a grid</p>
        <p>spelling out "7:46" on my phone screen</p>
        <p>as the ringing sound of the alarm</p>
        <p>oozes out and</p>
        <p>bounces off the walls of the room</p>
        <p>sticking to the hairs on my face and arms forever.</p>
        <br />
        <p>out there in the physical world i go</p>
        <p>inside and outside of trains above and under the ground</p>
        <p>and in and out of the sunlight</p>
        <p>as their humming slowly becomes</p>
        <p>imprinted somewhere on my skin and face.</p>
        <br />
        <p>I'm made out of parallel lines,</p>
        <p>electric and VGA cables, piles of dirt,</p>
        <p>books I'd found by mistake,</p>
        <p>and words i read on screens everyday.</p>
        <p>people's voices get stuck to my tongue</p>
        <p>and get swallowed inadvertently,</p>
        <p>our time together shapes the both of us.</p>
        <p>encode and decode,</p>
        <p>write and write over,</p>
        <p>things leave and come back</p>
        <p>with their colors changed,</p>
        <p>with their bodies changed,</p>
        <p>summing up all they've ever taken or was given</p>
        <p>to them.</p>
        <br></br>
        <p>on a screen somewhere,</p>
        <p>red thoughts and green and blue ones,</p>
        <p>the world stares back as we stare at it</p>
        <p>and leaves specks of its gaze on our skin,</p>
        <p>in the place where i'm now, with the parts i'm made out of,</p>
        <p>with the people i know and the things we've exchanged,</p>
        <p>layers of red, green and blue on top of one another</p>
        <p>convey an image of me.</p>
      </div>
    ),
  },
  {
    id: 2,
    title: "parannoul",
    isImage: false,
    text: (
      <div id="_idContainer003" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">parannoul</span>
        </p>
        <p> desynchronized breathing,</p>
        <p>reflections of reflections,</p>
        <p>trying to summon the strength to stand still</p>
        <p>when everything around is moving at increasing speeds,</p>
        <p>each on their own</p>
        <p>dotted line trajectories,</p>
        <p>infinite amounts of birds jam-pack the sky,</p>
        <p>endless</p>
        <p>automobiles,</p>
        <p>headlights and stop lights and check lights and</p>
        <p>voices decoded from radio waves.</p>
        <p>here i am</p>
        <p>fluctuating between self-destruction and self-preservation,</p>
        <p>hallucinations more real than the physical world.</p>
        <br></br>
        <p>in a train somewhere</p>
        <p>thoughts rumble and crawl on the ground,</p>
        <p>the ceiling expanding,</p>
        <p>motors endlessly running,</p>
        <p>a blink of the eye, a blip on the screen,</p>
        <p>the morning star gleams, a dot on the pale blue sky,</p>
        <br></br>
        <p>the same place for the billionth time,</p>
        <p>and nothing's the same,</p>
        <p>and everything’s changed,</p>
        <p>cells get replaced, a </p>
        <p>never-ending rewriting of the present,</p>
        <p>a rhythm of all instruments going out in the same time,</p>
        <p>blue sky, gray sun, white clouds, </p>
        <p>watching the world as it does its equations.</p>
      </div>
    ),
  },
  {
    id: 3,
    isImage: true,
    image: (
      <div id="_idContainer004" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={parannoul} alt="" />
      </div>
    ),
  },
  {
    id: 4,
    title: "condensation",
    isImage: false,
    text: (
      <div id="_idContainer005" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">condensation</span>
        </p>
        <p>some of the things i feel may not be mine,</p>
        <p>and instead have been passed down through generations</p>
        <p>or i have been unknowingly gathering them in my body,</p>
        <p>particles clumping up into shapes,</p>
        <p>cloud-like, typhoon-like,</p>
        <p>yellow taped on the side of the road,</p>
        <p>getting in the way of cells </p>
        <p>transiting through the body.</p>
        <br></br>
        <p>wind vanes, radars, weather stations,</p>
        <p>storm clouds are always something that comes from far away,</p>
        <p>swirling blotches of white on a computer map,</p>
        <p>something the weather anchors warn about,</p>
        <p>except for the times when you unknowingly manifest them yourself,</p>
        <p>cloud-like, typhoon-like,</p>
        <p>storms are never something you choose to materialize</p>
        <p>and yet in that moment it feels like</p>
        <p>there's no other option in sight,</p>
        <p>thick clouds like black smoke choke what's left of the sky</p>
        <p>leaving the land drenched for months on end,</p>
        <br></br>
        <p>and only </p>
        <p>after that </p>
        <p>can you</p>
        <p>reach inside your body with your hand</p>
        <p>taking out wet clumps of</p>
        <p>cloud-like, typhoon-like</p>
        <p>tears of sorrow wept by other people's eyes.</p>
      </div>
    ),
  },
  {
    id: 5,
    isImage: true,
    image: (
      <div id="_idContainer004" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={condensation} alt="" />
      </div>
    ),
  },
  {
    id: 6,
    title: "count your blessings",
    isImage: false,
    text: (
      <div id="_idContainer006" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">count your blessings</span>
        </p>
        <p> there's no place close by when you can truly take in</p>
        <p>the sheer size of the world</p>
        <p>besides the parking lot of the nearest shopping center,</p>
        <p>in the middle of it, surrounded by carefully</p>
        <p>equidistantly placed decorative trees</p>
        <p>you can take in the night sky in its</p>
        <p>abridged but still reasonably sized glory,</p>
        <p>itself surrounded by</p>
        <p>egg yolk yellows gushing out of street lights.</p>
        <p>you take what you can,</p>
        <p>you count your blessings.</p>
        <br />
        <p>50 feet away from my apartment block</p>
        <p>there's a place where if you're alone at the right time</p>
        <p>the sky opens up like the folds of an eye</p>
        <p>with the moon as its pupil.</p>
        <p>50 feet away from my apartment block there's a place</p>
        <p>where it looks like you can stare God in the eye</p>
        <p>and when it rains, the water dripping</p>
        <p>sounds like somebody's whispers.</p>
        <br />
        <p>you count your blessings</p>
        <p>regardless of landscape elevation,</p>
        <p>you break some expectations and dial back others,</p>
        <p>sometimes you keep your head high and sometimes you don't.</p>
        <p>
          surreptitiously staring into the eyes of whatever beast is nearby,
        </p>
        <p>pupils so black they ignore the rest of the eye color,</p>
        <p>what else is there to do but keep going?</p>
      </div>
    ),
  },

  {
    id: 7,
    isImage: false,
    title: "how close",
    text: (
      <div id="_idContainer007" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">how close</span>
        </p>
        <p> how close is too close,</p>
        <p>how far away is it not close enough,</p>
        <p>i'm peeling off layers,</p>
        <p>inside, the walls seem to be breathing,</p>
        <p>
          somehow i have no air, i might have pushed it all down your throat,{" "}
        </p>
        <p>somehow i might have suffocated myself.</p>
        <br />

        <p>how close is it too close,</p>
        <p>it is when i</p>
        <p>unexpectedly</p>
        <p>find my words in your mouth,</p>
        <p>or is it when the thought of purposely putting them there</p>
        <p>comes to mind?</p>
        <br />
        <p>how much further is it ?</p>
        <p>so far away that the things you come up with are just</p>
        <p>copies of a copy,</p>
        <p>how far away is it that the real thing looks nothing like</p>
        <p>what you've had in mind so far.</p>
        <br />

        <p>floating on a raft in a body of water of unknown size,</p>
        <p>sunlight stings on my skin</p>
        <p>the water is pleasant but not good to drink,</p>
        <p>it can take days and nights on this raft,</p>
        <p>i know i'm bound to float my way to shore eventually.</p>
      </div>
    ),
  },
  {
    id: 8,
    isImage: true,
    image: (
      <div id="_idContainer009" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={rainsong} alt="" />
      </div>
    ),
  },
  {
    id: 9,
    title: "rain song",
    isImage: false,
    text: (
      <div id="_idContainer008" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">rain song</span>
        </p>
        <p> today's memories are wet</p>
        <p>and they smell like dirt and stones that've been rained on,</p>
        <p>they're made out of</p>
        <p>the crinkling sound of raincoat nylon rubbing against itself,</p>
        <p>tires rolling on the wet asphalt,</p>
        <p>and out of images of</p>
        <p>people looking for shelter and lighting up cigarettes,</p>
        <p>wet shoe prints on the wooden floor,</p>
        <p>the faint music-like noise of water dripping around us.</p>
        <br />
        <p>somewhere at ground level</p>
        <p>i dance with my words in a familiar way,</p>
        <p>i let them spin and then come back to me.</p>
        <p>in between them there's silence that you can walk on,</p>
        <p>a breather,</p>
        <p>a dry patch of white space,</p>
        <p>is this what you want to hear?</p>
        <p>does this feel easy to slip into with your</p>
        <p>own words?</p>
        <br />
        <p>today's memories are made out of</p>
        <p>you and i exchanging words for the first time.</p>
        <p>it's pleasantries, it's centimeters of</p>
        <p>back and forth movement,</p>
        <p>ripples stirring aimlessly</p>
        <p>among drops of water falling into puddles</p>
        <p>and soaking into our clothes.</p>
      </div>
    ),
  },

  {
    id: 10,
    title: "ripple",
    isImage: false,
    text: (
      <div id="_idContainer010" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">ripple</span>
        </p>
        <p> there's a moment in time</p>
        <p>when i stopped thinking about what will happen and instead</p>
        <p>started thinking about what had happened in the past,</p>
        <p>desire had become uncertainty,</p>
        <p>in my mind</p>
        <p>the act of growing had become the act of wilting.</p>
        <p>responsibility and danger, closely intertwined.</p>
        <p>under the soles of my feet stood</p>
        <p>traces of the things i've unknowingly trampled on.</p>
        <br />
        <p>looking back, looking forwards,</p>
        <p>time is a boat on a river in which you fall asleep</p>
        <p>and in which you wake up</p>
        <p>the soil of the shore having changed color,</p>
        <p>am i adrift?</p>
        <p>spring water, clear water, sea water,</p>
        <p>the rooms in which i live are strangers to me</p>
        <p>until we get acquainted,</p>
        <p>the colors of the sky get forgotten and then they get remembered,</p>
        <p>connections form and break,</p>
        <p>they're left behind and then picked up again,</p>
        <p>a cascade in the skin,</p>
        <p>a ripple,</p>
        <p>small shifts in how to approach situations.</p>
      </div>
    ),
  },
  {
    id: 11,
    isImage: true,
    image: (
      <div id="_idContainer009" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={ripple} alt="" />
      </div>
    ),
  },
  {
    id: 12,
    title: "walking in the countryside at dusk",
    isImage: false,
    text: (
      <div id="_idContainer012" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">
            walking in the countryside at dusk
          </span>
        </p>
        <p> conversations sometimes feel like looking for the right path</p>
        <p>in a hazy evening with barely any sunlight left.</p>
        <p>the shapes of leaves and plants nearby</p>
        <p>blend with one another in the dark,</p>
        <p>i'm gauging wherever we're getting closer</p>
        <p>based on your expressions,</p>
        <p>searching for markers in the folds of your skin,</p>
        <br />
        <p>there are moments when</p>
        <p>we stumble upon lit paths,</p>
        <p>road lights shining down on the dusty pathway,</p>
        <p>covered in the tracks of cars and tractors from nearby towns.</p>
        <p>my words navigate this way,</p>
        <p>swerving every now and then,</p>
        <p>looking for a place where</p>
        <p>we could stay for the night.</p>
        <br />

        <p>eyes on the road, half-expecting</p>
        <p>a dead end, or for the path to go through dense foliage</p>
        <p>in which we'll surely lose one another.</p>
        <p>but somehow we don't,</p>
        <p>our words come back to us having found a clearing,</p>
        <p>a trace of relief on both your skin and mine,</p>
        <p>i was</p>
        <p>so worried about what i sounded like,</p>
        <p>absorbed by the moment when this road might end</p>
        <p>that i forgot you had worries of your own.</p>
      </div>
    ),
  },
  {
    id: 13,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer013" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={walking} alt="" />
      </div>
    ),
  },
  {
    id: 14,
    title: "eye-motes",
    isImage: false,
    text: (
      <div id="_idContainer014" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">eye-motes</span>
        </p>
        <p> in the end, some things remain unsaid,</p>
        <p>while others are opened up and they spill all across the floor,</p>
        <p>and stain your shoes and make your feet all sticky,</p>
        <p>some things are better abandoned</p>
        <p>before they catch on fire</p>
        <p>and the whole building has to be evacuated.</p>
        <p>some things end or are ended,</p>
        <p>but a trace of them remains somewhere in your body</p>
        <p>for the rest of your days.</p>
        <p>what a silly thing indeed.</p>
        <br />
        <p>a second sight of what might've been</p>
        <p>in a trace of what remains,</p>
        <p>an eye-mote, a speck,</p>
        <p>
          a fragment of an immaterial thing brought into the physical realm,
        </p>
        <p>lingering in the corners of my eyes,</p>
        <p>i see it moving in and out of sight</p>
        <p>sometimes less so, other times in clear view,</p>
        <p>a particle of one of your past images.</p>
        <br />
        <p>eye-motes, spots on the skin, newly-found wrinkles,</p>
        <p>i open up the windows,</p>
        <p>the sun's so bright, my eyes stand no chance against it,</p>
        <p>regardless of the things in my view</p>
        <p>for a second there, there's nothing to see</p>
        <p>but the sun and the blue skies surrounding it.</p>
        <p>for a moment there i see you fade out of sight,</p>
        <p>for a second.</p>
      </div>
    ),
  },
  {
    id: 15,
    title: "visitor",
    isImage: false,
    text: (
      <div id="_idContainer015" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">visitor</span>
        </p>
        <p> some of the best things in life are yours alone</p>
        <p>be it the</p>
        <p>warmth of your home, the</p>
        <p>embrace of someone close,</p>
        <p>or the act of looking back at what you made so far,</p>
        <p>yet others</p>
        <p>belong to the world,</p>
        <p>and you're allowed to partake:</p>
        <p>the</p>
        <p>warmth in the sun's gaze and the </p>
        <p>weight of the wind laying on your skin,</p>
        <p>a breath of cold air in the morning,</p>
        <p>the sound of the world so strange and ornate</p>
        <p>and yet so familiar.</p>
        <br />
        <p>you give and take from other human beings,</p>
        <p>you give and take from the world,</p>
        <p>and yet</p>
        <p>it's easy to forget</p>
        <p>that in that skittering of steps</p>
        <p>a pair of those feet is yours,</p>
        <p>or how out of these gusts of air</p>
        <p>some of it you breathe in,</p>
        <p>not a link in a chain or a piece of a puzzle,</p>
        <p>but a drop somewhere on a surface of water,</p>
        <p>carrying further</p>
        <p>ripples from an infinite amount of shapes surrounding it.</p>
      </div>
    ),
  },

  {
    id: 16,
    title: "",
    isImage: true,
    image: (
      <div id="_idContainer017" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={soak} alt="" />
      </div>
    ),
  },

  {
    id: 17,
    title: "soak",
    isImage: false,
    text: (
      <div id="_idContainer016" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">soak</span>
        </p>
        <p> we imagine ourselves to be way bigger</p>
        <p>than we actually are.</p>
        <p>the shape of your face and body,</p>
        <p>inches away from mine</p>
        <p>covers almost everything in view.</p>
        <p>sunlight falls on whatever patches of skin we've exposed to it,</p>
        <br />
        <p>it's all a matter of perspective.</p>
        <p>it's all a matter of distance. of closeness, perhaps,</p>
        <p>there are things about you i can only see from this close.</p>
        <p>i imagine it must be the same way about me.</p>
        <br />
        <p>for the moment we continue resting,</p>
        <p>letting our bodies soak in the heat,</p>
        <p>it'll be time to get up soon,</p>
        <p>until then though,</p>
        <p>i try to not move so sudden that it might disturb your sleep.</p>
      </div>
    ),
  },

  {
    id: 18,
    title: "Yellow River Blue",
    isImage: false,
    text: (
      <div id="_idContainer018" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">Yellow River Blue</span>
        </p>
        <p> gentle haze,</p>
        <p>spiders' webs in my oral cavity</p>
        <p>going up to my brain,</p>
        <p>a gentle warmth, a healthy amount of pain to be felt</p>
        <p>and to be overcome,</p>
        <p>my oily hands gleaming in the dull light of a cloudy sky,</p>
        <p>surrounded by these walls i am protected from harm.</p>
        <p>up here i am the only one</p>
        <p>
          and whenever the sky is clear i take the roof off and i soak up the
          sun
        </p>
        <p>with my skin and tongue,</p>
        <br />
        <p>some day will come when i will climb up to this spot</p>
        <p>only to find somebody else here, and with them i'll share in</p>
        <p>this focused aimlessness,</p>
        <p>and together we'll twist our stems</p>
        <p>opening up towards daylight</p>
        <br />
        <p>gorgeous geometry,</p>
        <p>open skies for you in which to float,</p>
        <p>spiders' webs, rodents' nests and burrows to live in,</p>
        <p>warmth in what is made for you,</p>
        <p>sunlight on your skin,</p>
        <p>the glow of the face of an old friend,</p>
        <p>neither light nor heavy,</p>
        <p>a calculated slowness.</p>
        <p>sometimes there's nothing to wait for</p>
        <p>but just the act of waiting seems like the right thing to do,</p>
        <p>wind currents swirl between my fingers,</p>
        <p>clouds inch away in their usual hover,</p>
        <p>
          i let light fall on me and bounce off my skin on whatever's around me.
        </p>
      </div>
    ),
  },
  {
    id: 19,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer019" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={yrb} alt="" />
      </div>
    ),
  },
  {
    id: 20,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer022" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={subterranean} alt="" />
      </div>
    ),
  },
  {
    id: 21,
    title: "subterranean",
    isImage: false,
    text: (
      <div id="_idContainer021" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">subterranean</span>
        </p>
        <p> down into</p>
        <p>subway stations and pedestrian tunnels,</p>
        <p>inside glass pane reflections in murky tiled hallways</p>
        <p>we may meet each other</p>
        <p>as white flashes of distant neon lights,</p>
        <p>as</p>
        <p>spots of light on the walls and floors streaking</p>
        <p>off people's buckles and jewelry,</p>
        <p>we'll meet in the middle of night</p>
        <p>in shadows smeared on top of the ceilings of nearby apartments</p>
        <p>as cars pass on the street with their headlights on,</p>
        <p>we'll meet in the scribbled haze your eyes try to decipher</p>
        <p>whenever you're staring at something in the dark,</p>
        <p>in the buzzing</p>
        <p>of home appliances,</p>
        <p>in through the slits of the window blinds,</p>
        <p>out of LED lights in the darkness of the room,</p>
        <p>a couple of seconds for</p>
        <p>thoughts in the brain of the world,</p>
        <p>soulful electrochemical impulses,</p>
        <p>electronic soothsayer sighs,</p>
        <br />
        <p>somewhere close enough but still far from here</p>
        <p>in a plastic case, on a circuit board,</p>
        <p>in stray drops of paint dried out on the ground,</p>
        <p>in a place unknown,</p>
        <p>a moment soldered together.</p>
        <p>in a place with no weight</p>
        <p>we will stumble upon one another</p>
        <p>as if we're well</p>
        <p>acquainted.</p>
      </div>
    ),
  },
  {
    id: 22,
    title: "t h i n g s",
    isImage: false,
    text: (
      <div id="_idContainer022" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">t h i n g s</span>
        </p>
        <p> there's still time to make mistakes.</p>
        <p>mistakes you've made before and sworn you'll never</p>
        <p>do again,</p>
        <p>mistakes you've never even thought of.</p>
        <p>there's still ways to trip and fall</p>
        <p>even on a flat plane,</p>
        <p>knees aching to clash with the ground,</p>
        <p>screams of pain and frustration eagerly waiting to</p>
        <p>come out of your throat,</p>
        <p>it's the feeling</p>
        <p>of being</p>
        <p>overwhelmed,</p>
        <p>the rush of letting go,</p>
        <p>things you didn't know you had until</p>
        <p>you lose a bunch of other ones.</p>
        <br />
        <p>things wait.</p>
        <p>people, feelings, houses, streets, conversations,</p>
        <p>fights, make-ups, glances that carry weight,</p>
        <p>words to be spilled on the floor,</p>
        <p>things wait to happen until</p>
        <p>they can not wait anymore,</p>
        <p>and some of them you catch</p>
        <p>and feed and water and watch them grow,</p>
        <p>others come to you themselves</p>
        <p>ramming their heads into your chest,</p>
        <p>and some of them get away forever,</p>
        <p>never to be seen again</p>
        <p>and</p>
        <p>God only knows what happens to those.</p>
      </div>
    ),
  },
  {
    id: 23,
    title: "unearth",
    isImage: false,
    text: (
      <div id="_idContainer023" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">unearth</span>
        </p>
        <p> are we done yet?</p>
        <p>is this over?</p>
        <p>words lose their meaning unless you're in the right headspace</p>
        <p>to listen to them,</p>
        <p>years turn into moments that flash in the blink of an eye</p>
        <p>whenever blood starts boiling.</p>
        <p>my body's willingness to change shape at the sound of your words</p>
        <p>disregards my own desire to not do so.</p>
        <br />
        <p>all that it is</p>
        <p>is a room in the back of my head</p>
        <p>that your words always seem to reach somehow,</p>
        <p>it might not even be your words</p>
        <p>but the way i open them up,</p>
        <p>covering the walls of my brain with what they might mean,</p>
        <p>i</p>
        <p>build myself floors on top of structures i've found,</p>
        <p>
          wandering through rooms i'd unknowingly built and forgotten about,
        </p>
        <p>some rooms are small and some of them</p>
        <p>have paper-thin walls made when there was nothing but</p>
        <p>paper and driftwood to build with.</p>
        <p>sometimes</p>
        <p>
          words come at you as stampeding beasts that break their skulls and
          shoulder blades
        </p>
        <p>against the walls of any</p>
        <p>structures you may have built,</p>
        <p>yet every now and then one of them comes charging at you</p>
        <p>and all you can see is yourself</p>
        <p>reflected in the milky pulp of their eyes</p>
        <p>and it just</p>
        <p>makes you</p>
        <p>open the door.</p>
      </div>
    ),
  },

  {
    id: 24,
    title: "veils",
    isImage: false,
    text: (
      <div id="_idContainer024" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">veils</span>
        </p>
        <p> some things become visible only after</p>
        <p>they've chosen to be seen.</p>
        <p>a twitch in the eye that wasn't there before, a</p>
        <p>muted sigh, traces of a scowl in the wrinkles of the face,</p>
        <p>some people's worries look foreign until you've had</p>
        <p>worries of your own.</p>
        <br />
        <p>thoughts bubble up to the surface,</p>
        <p>they turn into words that you read</p>
        <p>on people's creases in their skin,</p>
        <p>they</p>
        <p>manifest themselves,</p>
        <p>things hidden</p>
        <p>become things that slip away.</p>
        <br />

        <p>sometimes it's not yours to give,</p>
        <p>it's not yours to choose to give away,</p>
        <p>
          the brain, the body, the soul, coming out with minds of their own,
        </p>
        <p>every now and then things slip out of everyone's control,</p>
        <br />

        <p>all through</p>
        <p>escape hatches to get out,</p>
        <p>white lies and stories to build,</p>
        <p>objects that glimmer and draw away attention,</p>
        <p>things from outside that numb things in the body,</p>
        <p>words that don't mean anything anymore</p>
        <p>unless you want them to,</p>
        <p>it's amazing how many ways there are</p>
        <p>to run away from</p>
        <p>it.</p>
      </div>
    ),
  },
  {
    id: 25,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer025" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={veils} alt="" />
      </div>
    ),
  },
  {
    id: 26,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer026" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={spread1} alt="" />
      </div>
    ),
  },
  {
    id: 27,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer027" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={spread2} alt="" />
      </div>
    ),
  },

  {
    id: 28,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer028" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={thismoment} alt="" />
      </div>
    ),
  },
  {
    id: 29,
    title: "this moment",
    isImage: false,
    text: (
      <div id="_idContainer029" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">this moment</span>
        </p>
        <p> there's a place between</p>
        <p>this world and the next one</p>
        <p>where the string of fate unravels,</p>
        <p>where everything gets in and yet nothing gets out,</p>
        <p>where there's no future to look up to or past to </p>
        <p>look back on.</p>
        <br />
        <p>life happens in micro-moments and temporary lapses of focus,</p>
        <p>what is fate like and how does it appear to one,</p>
        <p>if you think too much about it you might enter this space where</p>
        <p>there is no such thing as fate,</p>
        <p>the present - a dot in an endless line,</p>
        <p>the present - the peak of an unscalable mountain,</p>
        <p>
          cold air stampedes towards you and it hits your skin and it feels like
        </p>
        <p>there's no time for it to feel like anything</p>
        <p>and yet</p>
        <p>life happens regardless,</p>
        <p>every living being acting on temporary knowledge.</p>
        <br />
        <p>life happening in the space between people</p>
        <p>waiting for the bus in their daily commute,</p>
        <p>sunlight gets in my eyes and my nose and my mouth,</p>
        <p>indifferent to my twists and turns,</p>
        <p>lines of sight sometimes meet as gazes intersect.</p>
        <p>human skin and atoms of people</p>
        <p>come close to colliding and somehow</p>
        <p>always slide against one another without doing so,</p>
        <p>the string of fate gets tied and untied and spun and unraveled</p>
        <p>again and again.</p>
      </div>
    ),
  },
  {
    id: 30,
    title: "witness",
    isImage: false,
    text: (
      <div id="_idContainer030" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">witness</span>
        </p>
        <p> it's all about either</p>
        <p>being in the light sucking up the brightness</p>
        <p>or being in the dark,</p>
        <p>watched by hidden witnesses,</p>
        <p>except for </p>
        <p>the times when it's not.</p>
        <br />
        <p>neither daylight nor night time,</p>
        <p>it's this place of a perpetually cloudy afternoon,</p>
        <p>in which you sometimes wake up,</p>
        <p>fated to drift through it</p>
        <p>until you return to the regular world.</p>
        <p>sometimes it looks like your kitchen in the morning</p>
        <p>while you wait for water to boil in the kettle,</p>
        <p>other times it looks like your bedroom at 2 AM,</p>
        <p>or 2 PM. or 6 PM. or 6 AM. who knows.</p>
        <p>days are just shiny pebbles on a shore somewhere</p>
        <p>and nights are but spots of dimness underneath</p>
        <p>rocks and blades of grass on the ground.</p>
        <br />

        <p>sometimes you got to shake your body awake,</p>
        <p>when you got things to do,</p>
        <p>mouths to feed, your shoulders to be leaned on,</p>
        <p>other times</p>
        <p>there's no way around it,</p>
        <p>you have to cross this sea,</p>
        <p>have to let your body be carried to the shore,</p>
        <p>like watching a flood once you've saved everything of value</p>
        <p>or coming to terms with newfound empty space.</p>
        <br />

        <p>i woke up one day,</p>
        <p>a spot of sunlight on my skin like a shiny new pebble,</p>
        <p>it gleamed and felt warm to the touch,</p>
        <p>words don't do justice to moments like these.</p>
      </div>
    ),
  },
  {
    id: 31,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer031" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={witness} alt="" />
      </div>
    ),
  },
  {
    id: 32,
    title: "hyperstranger",
    isImage: false,
    text: (
      <div id="_idContainer032" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">hyperstranger</span>
        </p>
        <p> ley lines on the seams of our clothes,</p>
        <p>our feet on the ground are always</p>
        <p>yearning</p>
        <p>to find something else,</p>
        <p>other avenues of the universe -</p>
        <p>debilitating thoughts of stepping into the unknown.</p>
        <br />
        <p>what is it like to find something </p>
        <p>that doesn't</p>
        <p>look like anything you know,</p>
        <p>so foreign you don't even know how to react,</p>
        <p>neural synapses, electrified</p>
        <p>snake their way through flesh,</p>
        <p>i'm never ready for it and yet</p>
        <p>i always desire it.</p>
        <br />

        <p>inside</p>
        <p>the endlessness of the sky,</p>
        <p>the vastness of dirt and asphalt covered landmasses,</p>
        <p>in the billions of atoms that make up the rest of the cosmos</p>
        <p>there might be nothing but empty space,</p>
        <p>nothing out there that you haven't seen already.</p>
        <br />

        <p>the world and i</p>
        <p>know of each other, have met some of the same people,</p>
        <p>acquaintances made,</p>
        <p>names learned and written somewhere in the brain,</p>
        <p>seen each other on the street and in the city</p>
        <p>and yet</p>
        <p>i don't know if we've ever</p>
        <p>truly spoken to one another.</p>
      </div>
    ),
  },
  {
    id: 33,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer033" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={hyperstranger} alt="" />
      </div>
    ),
  },
  {
    id: 34,
    title: "ooze",
    isImage: false,
    text: (
      <div id="_idContainer034" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT CharOverride-2">ooze</span>
        </p>
        <p> reach for the stars</p>
        <p>with your hands and fingers</p>
        <p>and pull down the curtain,</p>
        <p>my big mouth is ready to ingest</p>
        <p>burning helium hydrogen baubles,</p>
        <p>tense musculature, are we ready to</p>
        <p>throw ourselves against rapidly approaching</p>
        <p>brick and concrete walls?</p>
        <p>a structure through which</p>
        <p>i want to cannonball with my body,</p>
        <p>bursting through dust particles like i am the projectile</p>
        <p>making all that ground rattle in war documentaries.</p>
        <br />
        <p>it's in the moment,</p>
        <p>grabbing that slithering thing,</p>
        <p>cutting it up and laying it down on the paper,</p>
        <p>you wash your hands off the guts</p>
        <p>and wonder</p>
        <p>what's inside your ribcage </p>
        <p>and lungs;</p>
        <br />

        <p>steam,</p>
        <p>burning h2o and electricity,</p>
        <p>i ooze heat,</p>
        <p>i want to be reckless,</p>
        <p>i reach for the sky with my tongue salivating,</p>
        <p>the sound coming out this diaphragm will flood</p>
        <p>like rapid rivers and swarms </p>
        <p>the eardrums of whoever's listening </p>
        <p>regardless of how </p>
        <p>many</p>
        <p>may or may not be.</p>
      </div>
    ),
  },
  {
    id: 35,
    title: "viscous",
    isImage: false,
    text: (
      <div id="_idContainer035" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT CharOverride-2">viscous</span>
        </p>
        <p> night time,</p>
        <p>smoking a cigarette despite planning to quit last month.</p>
        <p>neighborhood cats sometimes </p>
        <p>streak down the street like drops of dusky paint through</p>
        <p>streetlight diluted darkness,</p>
        <p>i feel my lungs disagreeing with my choice of</p>
        <p>passing the time.</p>
        <p>out there,</p>
        <p>on the outside,</p>
        <p>the world seems quieter than</p>
        <p>even the inside of this room,</p>
        <p>yet some days i see its</p>
        <p>stained limbs flailing around splashing thick drops</p>
        <p>
          on the asphalt and on the sides of cars and on people's clothes, with
        </p>
        <p>
          wind sweeping leaves and pieces of paper and pieces of cloth off the
          ground,
        </p>
        <p>always twisting its bloodrushed hands and feet, </p>
        <p>and some days</p>
        <p>in its sluggish limp</p>
        <p>it just</p>
        <p>refuses to be seen.</p>
        <br />
        <p>viscous stillness drips out of the room,</p>
        <p>down the window out into the world's outside,</p>
        <p>together they mix,</p>
        <p>on a thickly coated canvas,</p>
        <p>i assume in this picture,</p>
        <p>I'm probably but a human shaped smudge of paint</p>
        <p>in a window somewhere</p>
        <p>of an apartment block on the fifth floor.</p>
      </div>
    ),
  },

  {
    id: 36,
    title: "the same things often enough",
    isImage: false,
    text: (
      <div id="_idContainer036" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">
            the same things often enough
          </span>
        </p>
        <p> it's been really quiet lately</p>
        <p>and my words misplaced and sleeping somewhere</p>
        <p>are now part of the quiet.</p>
        <p>people dry up and wrinkle and become part of their clothes,</p>
        <p>bodies become parts of dressed up things,</p>
        <p>my hands are now pieces of the thing that is typing this verse</p>
        <p>on a telephone screen.</p>
        <br />
        <p>lines become unclear, that's the only thing that's certain.</p>
        <p>for a minute there i forget where i end and where</p>
        <p>the next person begins.</p>
        <p>what day is it?</p>
        <p>you see the same places often enough</p>
        <p>so much that they start to shrink</p>
        <br />

        <p>for a minute there it felt like i had it figured out.</p>
        <p>for a minute there it felt like i didn't.</p>
        <p>and for those 60 seconds i stepped into another body</p>
        <p>and forgot which one was mine.</p>
        <p>time passes or sneaks its way past me,</p>
        <p>sometimes i catch up but sometimes</p>
        <p>i become part of the background.</p>
        <p>i saw a sea of faces these past couple of days,</p>
        <p>i heard a lot of noise</p>
        <p>and for a moment there, there was so much to see and hear</p>
        <p>that i could not make anything out of it.</p>
      </div>
    ),
  },
  {
    id: 37,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer037" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={thesamethings} alt="" />
      </div>
    ),
  },
  {
    id: 38,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer038" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={gravity} alt="" />
      </div>
    ),
  },
  {
    id: 39,
    title: "gravity",
    isImage: false,
    text: (
      <div id="_idContainer039" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">gravity</span>
        </p>
        <p> standing in the tram wondering about my posture</p>
        <p>
          and how in its spin, I might one day unknowingly fall off the planet,
        </p>
        <p>lately</p>
        <p>days seem to have swept by me</p>
        <p>I might have misplaced some of my hours,</p>
        <p>i might have left words in a pair of jeans</p>
        <p>and they might have dissolved</p>
        <p>in the cycles of the washing machine,</p>
        <br />

        <p>standing in the tram,</p>
        <p>leaning against the inside of its ribs</p>
        <p>slithering towards home,</p>
        <p>some things just stand straight unconsciously,</p>
        <br />
        <p>get off this tram and on to another tram,</p>
        <p>this one's packed, there's no space to worry about posture,</p>
        <p>in people's breaths there's the air of working hours,</p>
        <p>some of it floats up out the open windows,</p>
        <p>some of it condenses down on steels rails and wood paneling,</p>
        <p>I lose focus for a second,</p>
        <p>my feet unstick from the floor,</p>
        <p>my body softens as I float out the window and into foggy sky,</p>
        <p>where's gravity in moments like these</p>
        <p>
          there's things to hold on to but my fingers won't curl around them
        </p>
        <p>trying hard not slouch while i'm evaporating into outer space,</p>
        <p>I guess all there is to do now is wait until i arrive at my stop.</p>
      </div>
    ),
  },

  {
    id: 40,
    title: "closed system",
    isImage: false,
    text: (
      <div id="_idContainer040" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">closed system</span>
        </p>
        <p> in a petri-dish in which to live out life,</p>
        <p>most days look the same under lab conditions,</p>
        <p>most streets bear the shape of the soles of your feet,</p>
        <p>and most faces are seen often enough</p>
        <p>that they blend in with the rest of your body.</p>
        <br />
        <p>some of these days</p>
        <p>all there seems to be out there</p>
        <p>is barren fields that bear no crops </p>
        <p>and gnaw at your ankles.</p>
        <p>otherwise all of this ecosystem's</p>
        <p>living creatures and structures</p>
        <p>sustain their routines</p>
        <p>of daytime sleepwalking,</p>
        <p>closed systems interacting</p>
        <p>fuel passing through their pipes,</p>
        <p>acting out their actions and generating heat.</p>
        <p>i've been searching for a way out for a while.</p>
        <p>but so far i've only bumped my head against glass walls</p>
        <p>made out of uncertainty.</p>
        <br />
        <p>sometimes the lid comes off</p>
        <p>
          and we get glimpses of a night sky so infinite i get dizzy looking at
          it,
        </p>
        <p>
          i've yet to learn how to flap my wings or jump high enough to touch
          its edges
        </p>
        <p>so whenever i get the chance i try to</p>
        <p>
          imagine the shapes of the things and alien languages spoken outside,
        </p>
        <p>words trying to describe what is out there</p>
        <p>falling out of the dome-shaped sky</p>
        <p>inside the roof of my mouth.</p>
      </div>
    ),
  },
  {
    id: 41,
    title: "swollen",
    isImage: false,
    text: (
      <div id="_idContainer041" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">swollen</span>
        </p>
        <p> the path eventually reached a clearing</p>
        <p>and the sky became visible,</p>
        <p>clouds laid out in waves</p>
        <p>like some kind of sea somewhere up above.</p>
        <p>there's no fighting to be done under a blue this pale,</p>
        <p>and yet</p>
        <p>something about it seemed swollen,</p>
        <p>bruises waiting to manifest,</p>
        <p>even</p>
        <p>in a dome this clear</p>
        <p>there was something aching to get out,</p>
        <p>or perhaps to be driven out by the rest of the body. </p>
        <br />
        <p>ultimately</p>
        <p>everything wants to be listened to</p>
        <p>regardless of its color or outside exterior,</p>
        <p>everything wants to sulk, to have</p>
        <p>a third party help them pull out the things</p>
        <p>they're trying to take out.</p>
        <p>underneath its outer layers,</p>
        <p>things stay hidden inside the body until they start</p>
        <p>aching to get out.</p>
        <br />
        <p>it's not easy to mirror its color,</p>
        <p>the sky starts to dim.</p>
        <p>its waves blur and dissolve into the rest of the blueness,</p>
        <p>nothing today, it seems,</p>
        <p>as the dimness marks the end of this talk,</p>
        <p>the path continues forwards,</p>
        <p>
          thick layers of leaves above begin to block out what's left of this
          day.
        </p>
      </div>
    ),
  },
  {
    id: 42,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer042" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={planettelex} alt="" />
      </div>
    ),
  },
  {
    id: 43,
    title: "planet telex",
    isImage: false,
    text: (
      <div id="_idContainer043" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">planet telex</span>
        </p>
        <p> days out of vending machines,</p>
        <p>there’s the floor, the soles of my shoes, and then there’s me,</p>
        <p>everything just watches as we</p>
        <p>send work emails to one another.</p>
        <br />
        <p>days made out of 99.9% regular living</p>
        <p>and 0.1% out-of-body daydreaming,</p>
        <p>waiting for stoplights to change colors,</p>
        <p>moments that feel like other moments,</p>
        <p>the whole act of escaping requires</p>
        <p>something to be escaped out of.</p>
        <p>days made out of anticipation and expectations,</p>
        <p>I figured all I craved was to not know anything.</p>
        <p>you look at the clock and it’s 8:07 AM.</p>
        <p>you look at the clock and it’s 1:24 PM,</p>
        <p>"when" and "ago" separated by a couple of eye blinks,</p>
        <br />
        <p>computer smiles and copy-pasted theories,</p>
        <p>look how the world folds and unfolds itself,</p>
        <p>tethered to the physical 99.9% of the time -</p>
        <p>looking for that one minute of the day where the threads just</p>
        <p>unravel,</p>
        <p>a glitch in a bulletproof mirage,</p>
        <p>mistakes in the making,</p>
        <p>life without buildings,</p>
        <p>accidental meaning,</p>
        <p>the whole act of searching requires something to be searched for,</p>
        <p>days out of vending machines,</p>
        <p>i take it out and throw away the wrapper,</p>
        <p>the whole city, crinkled and crumpled,</p>
        <p>unfolds itself and straightens itself out again for every day.</p>
      </div>
    ),
  },
  {
    id: 44,
    title: "haunt",
    isImage: false,
    text: (
      <div id="_idContainer044" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">haunt</span>
        </p>
        <p> the one thing with living in a city is that</p>
        <p>everything that happens to it</p>
        <p>remains </p>
        <p>etched in its body,</p>
        <p>everything that happens in it</p>
        <p>lingers -</p>
        <p>in pavement synapses,</p>
        <p>half-truths glimmer in spots of motor oil on the asphalt,</p>
        <p>people's wrinkles photocopied onto concrete walls,</p>
        <p>both moments of joy and worries</p>
        <p>evaporate into the atmosphere</p>
        <p>and come back to the populace</p>
        <p>as heavy rainfall,</p>
        <p>the whole spectrum of human living</p>
        <p>including but not limited to</p>
        <p>the grainy dullness of daily commutes,</p>
        <p>the warmth of cherishing a friend's laughter,</p>
        <p>or the muted pain of watching parents age away,</p>
        <p>kaleidoscoped hues in an ever-spinning color wheel,</p>
        <br />
        <p>it's in the nicks in the paint on the walls.</p>
        <p>it's in weathered plastic cases,</p>
        <p>in worn out shoes and intricate shoe prints on the asphalt,</p>
        <p>traces of the wearer,</p>
        <br />

        <p>a handful of unknown pressure on your shoulders,</p>
        <p>it comes back to haunt you -</p>
        <p>other people's lives,</p>
        <p>never here right now but always out there nearby,</p>
        <p>foreign footprints on the ground</p>
        <p>when you find yourself alone on the street.</p>
      </div>
    ),
  },
  {
    id: 45,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer045" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={haunt} alt="" />
      </div>
    ),
  },
  {
    id: 46,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer046" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={wired} alt="" />
      </div>
    ),
  },
  {
    id: 47,
    title: "The Wired",
    isImage: false,
    text: (
      <div id="_idContainer047" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">The Wired</span>
        </p>
        <p> computer fans whirring,</p>
        <p>tv static in the room, a</p>
        <p>low quality silence,</p>
        <p>emotions with no names, pieces of an</p>
        <p>indivisible whole,</p>
        <p>i find myself obsessed with ends and beginnings</p>
        <p>it's either full or empty,</p>
        <p>disregarding all the times when it's not,</p>
        <p>how do you handle what it is</p>
        <p>when there are still so many things that have yet to</p>
        <p>reach the end of their threads,</p>
        <br />
        <p>restart the system</p>
        <p>every single moment,</p>
        <p>every single second,</p>
        <p>a beginning and an end,</p>
        <p>forever.</p>
        <p>every "now", an "always",</p>
        <p>every "soon", a "never",</p>
        <p>every "when", wiped from the database,</p>
        <p>LEDs blink, PC fans are spinning.</p>
        <p>it must mean that something's</p>
        <p>happening.</p>
      </div>
    ),
  },
  {
    id: 48,
    title: "blunt",
    isImage: false,
    text: (
      <div id="_idContainer048" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">blunt</span>
        </p>
        <p> autopilot gaze, numbers in an</p>
        <p>optical character recognition device,</p>
        <p>interfaces to interface with,</p>
        <p>where to turn when there's nothing to do</p>
        <p>but process data?</p>
        <p>a Turing test I'm not sure I could pass,</p>
        <p>days as database entries</p>
        <p>hours onto work logs,</p>
        <p>forgive me if i'd missed the clues</p>
        <p>and ended up in a spreadsheet somewhere.</p>
        <br />
        <p>the world gives and it takes away,</p>
        <p>days off and work laptops and employee benefits,</p>
        <br />
        <p>work clothes and house clothes and hours erased,</p>
        <p>i can never seem to be focused enough,</p>
        <p>a year's worth of sleep,</p>
        <p>specks in the light filtering through</p>
        <p>the windows of the bus,</p>
        <p>some poems remain unfinished</p>
        <p>because they're about nothing,</p>
        <p>some days are the same,</p>
        <p>a lifetime of computer screens,</p>
        <p>the sun is e-lec-tro-nic,</p>
        <p>so many people want you in their taskbars and toolbars,</p>
        <p>i wait for the subway to go home,</p>
        <p>for the tram and the green light ahead,</p>
        <p>days become</p>
        <p>what's left of the evenings.</p>
      </div>
    ),
  },

  {
    id: 49,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer049" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={blunt} alt="" />
      </div>
    ),
  },

  {
    id: 50,
    title: "pairs",
    isImage: false,
    text: (
      <div id="_idContainer050" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">pairs</span>
        </p>
        <p> find me behind closed doors and in people's mouths,</p>
        <p>in people's shoes and people's shirts and</p>
        <p>the clothes they wear to work,</p>
        <p>find me</p>
        <p>coiling myself inside the pipes of a running engine somewhere,</p>
        <p>most of the time I reside</p>
        <p>somewhere between the apartment block across the street</p>
        <p>and the layers of clouds and sky behind it</p>
        <br />
        <p>somewhere behind tufts of thick white clouds</p>
        <p>there's one of me in whom something happened</p>
        <p>one whose</p>
        <p>words are solid and do not budge,</p>
        <p>who waits for weather to come hit his face and chest,</p>
        <p>water drips on his skin like on a heavy ancient stone.</p>
        <br />
        <p>when we meet, I don't know where that will be,</p>
        <p>when we come to each other, I don't know which ones of us</p>
        <p>will come to one another,</p>
        <p>whatever happens is not to be known now,</p>
        <p>whatever stems out of our collision</p>
        <p>might very well be</p>
        <p>the same straining sensation in the back of your head,</p>
        <p>it might very well be</p>
        <p>the same lump in your throat when you're trying to breathe,</p>
        <p>days come like wind gusts with dust that sticks to the skin,</p>
        <p>out there,</p>
        <p>a silhouette, its contour flickers,</p>
        <p>I come to bring whatever that is</p>
        <p>that you need to move further.</p>
      </div>
    ),
  },

  {
    id: 51,
    title: "swallows returning in the evening",
    isImage: false,
    text: (
      <div id="_idContainer051" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">
            swallows returning in the evening
          </span>
        </p>
        <p> find me somewhere outside</p>
        <p>listening to the wind and the cries of birds,</p>
        <p>swallows nesting in the spring,</p>
        <p>find me out in the sun,</p>
        <p>thawing my feet in the heat,</p>
        <p>sucking up the light with my skin,</p>
        <p>find me when i</p>
        <p>look like someone else,</p>
        <p>after i've given the world its due toll.</p>
        <br />
        <p>that moment isn't here yet though,</p>
        <p>i still have mountains to move,</p>
        <p>oceans to cross,</p>
        <p>and things to take out of my body</p>
        <p>and then put them back in.</p>
        <br />

        <p>this evening</p>
        <p>the sun looked upon me with a warm gaze</p>
        <p>that i haven't seen in a while,</p>
        <p>i told him of the things i have to give to the world</p>
        <p>and how you've been searching for me.</p>
        <br />

        <p>i wanted you to find me,</p>
        <p>to scour the world for my sake,</p>
        <p>to bruise your elbows and knees in your pursuit,</p>
        <p>and yet when i came back to the place where we first met</p>
        <p>under a gazing dusk</p>
        <p>with no wind and no living creatures in sight,</p>
        <p>with nothing left to give to the world</p>
        <p>i might have realized</p>
        <p>i was the one who was searching all along.</p>
      </div>
    ),
  },
  {
    id: 52,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer052" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={thankstophysics} alt="" />
      </div>
    ),
  },

  {
    id: 53,
    title: "thanks to physics",
    isImage: false,
    text: (
      <div id="_idContainer053" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT CharOverride-2">thanks to physics</span>
        </p>
        <p> the smell of summer greenness cooling off in the evening,</p>
        <p>
          chlorophyll heat that dissipates from within leaves and patches of
          grass
        </p>
        <p>and floats up to my window:</p>
        <p>
          a token from the sun after it finished doling out light for the day,
        </p>
        <p>what else is there to do but take it,</p>
        <p>allow the cold to curl up around your fingers,</p>
        <p>receive the night despite its strange shape and color.</p>
        <p>
          welcome it despite the way it makes the world look for the next
          fistful of hours.
        </p>
        <br />
        <p>outside, the wind engulfs everything it touches.</p>
        <p>sounds echo in and out of its motion,</p>
        <p>silhouettes flicker in and out of its motion,</p>
        <p>you're either in it or looking at it,</p>
        <p>either in the process of being blown away</p>
        <p>or waiting your turn</p>
        <p>to be turned into</p>
        <p>atoms in some sort of night-colored matter,</p>
        <br />
        <p>we sit in the living room with the windows open,</p>
        <p>
          sometimes the only natural phenomena are the neurons firing up in our
          brains.
        </p>
        <p>the room is filled with the scent of the plants from outside,</p>
        <p>we're both jumbling words in our heads on our own.</p>
        <p>i go to close the windows:</p>
        <p>the universe shrinks,</p>
        <p>we continue living in light bulb daylight for the next</p>
        <p>couple of hours.</p>
      </div>
    ),
  },

  {
    id: 54,
    title: "yellow street light",
    isImage: false,
    text: (
      <div id="_idContainer054" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">yellow street light</span>
        </p>
        <p> there's nothing quite like</p>
        <p>coming again</p>
        <p>to the places where you've grown up as a child.</p>
        <p>the present stands still,</p>
        <p>it had stepped away from the foreground.</p>
        <p>all there is left are</p>
        <p>familiar scents</p>
        <p>and the shadows cast on the ground</p>
        <p>by unchanging things</p>
        <p>traced by firmly planted street lights </p>
        <p>that have yet to move from their spots.</p>
        <br />
        <p>i</p>
        <p>live in a different place now,</p>
        <p>the present somehow feels foreign,</p>
        <p>acquainted but not quite a friend,</p>
        <p>waking up to its polite half-smile,</p>
        <p>making my way through</p>
        <p>whichever new maze i'm in for the day,</p>
        <p>traffic lights, strange sunlight, white neon lights,</p>
        <p>light-emitting diode screens.</p>
        <br />

        <p>warm summer nights in a tired glassy-eyed city</p>
        <p>will always be a memory that lives</p>
        <p>somehow inside me</p>
        <p>beyond the confines of past, present and future.</p>
        <p>etched</p>
        <p>in the grooves of my skin is the yellow street light</p>
        <p>filtering through layers of leaves,</p>
        <p>bouncing off the hoods of the cars nearby and the asphalt </p>
        <p>underneath.</p>
      </div>
    ),
  },
  {
    id: 55,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer055" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={ysl} alt="" />
      </div>
    ),
  },
  {
    id: 56,
    title: "end",
    isImage: false,
    text: (
      <div id="_idContainer056" className="Basic-Text-Frame">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">electronic sun</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">octav codrea</span>
        </p>
        <br />
        <p>thank you for reading</p>
        <br />
        <p>2022</p>

        <div className="endvideo">
          <video src={endvideo} autoPlay loop></video>
        </div>
        {/* <p className="Basic-Paragraph"><span className="tEXT">This piece of work represents my final project and through it I attempt to synthesize my experiences from recent years, so close and in the same time so far from maturity.</span></p> */}
        {/* <p className="Basic-Paragraph"><span className="tEXT">The texts either mark or represent moods and experiences, while the images help to develop the context. I hope that together they might create an authentic image for the reader where they could resonate with the text and the ideas present in the book.</span></p> */}
        <b></b>
        {/* <p className="Basic-Paragraph"><span className="tEXT">Îndrumători: </span></p>
			<p className="Basic-Paragraph"><span className="tEXT">conf. Stela Lie</span></p>
			<p className="Basic-Paragraph"><span className="tEXT">assistent Carla Duschka</span></p>
			<p className="Basic-Paragraph"><span className="tEXT">Iunie 2019</span></p> */}
      </div>
    ),
  },
  {
    id: 57,
    title: "end-empty",
    isImage: true,
    image: (
      <div id="_idContainer057" className="_idGenObjectStyleOverride-1">
        <img
          className="_idGenObjectAttribute-1"
          src={ysl}
          style={{ opacity: 0 }}
          alt=""
        />
      </div>
    ),
  },
  {
    id: 58,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer058" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={coverback} alt="" />
      </div>
    ),
  },
  {
    id: 59,
    title: " ",
    isImage: false,
    text: (
      <div id="_idContainer059" className="_idGenObjectStyleOverride-1">
        <img
          className="_idGenObjectAttribute-1"
          src={ysl}
          style={{ opacity: 0 }}
          alt=""
        />
      </div>
    ),
  },
];

export default pages;
