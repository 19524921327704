import React, { useEffect, useState } from "react";
import pages from "../pages";
import logo from "../image/SVG/logo.svg";

interface ReadProps {
  selectTabHome: any;
}

const Read = (props: ReadProps) => {
  //Displays images and pieces of text stored in an array
  //Each page is represented by a .jsx element directly exported from Adobe InDesign

  const [pageNumber, setPageNumber] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [arrowsHidden, setArrowsHidden] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [canScroll, setCanScroll] = useState(true);

  const formatPoemList = () => {
    let poemTitleArray = [];

    for (let i = 0; i < pages.length; i++) {
      if (pages[i].isImage === false) {
        poemTitleArray.push({
          title: pages[i].title,
          pagenumber: pages[i].id + 2,
        });
      }
    }
    poemTitleArray = poemTitleArray.map((elem) => {
      return (
        <li
          key={`poem-${elem.title}`}
          onClick={() =>
            setPageNumber(2 * Math.round((elem.pagenumber - 1) / 2))
          }
          className={
            pageNumber === elem.pagenumber || pageNumber === elem.pagenumber - 1
              ? "selected"
              : ""
          }
        >
          {elem.title}
        </li>
      );
    });

    return <ul>{poemTitleArray}</ul>;
  };

  const goToNextPages = () => {
    if (pageNumber <= pages.length - 3) {
      setPageNumber(pageNumber + 2);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const goToPreviousPages = () => {
    if (pageNumber >= 2) {
      setPageNumber(pageNumber - 2);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  let pageA: any;
  if (pages[pageNumber].isImage) {
    pageA = pages[pageNumber].image;
  } else {
    pageA = pages[pageNumber].text;
  }

  let pageB: any;
  if (pages[pageNumber + 1].isImage) {
    pageB = pages[pageNumber + 1].image;
  } else {
    pageB = pages[pageNumber + 1].text;
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const handleScroll = () => {
    setArrowsHidden(false);
    if (timer !== null) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(() => setArrowsHidden(true), 3000));
  };

  function scrollbarVisible(element: HTMLElement) {
    return element.scrollHeight > element.clientHeight;
  }

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      setCanScroll(scrollbarVisible(root));
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());

      const root = document.getElementById("root");
      if (root) {
        setCanScroll(scrollbarVisible(root));
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="readTab">
      <nav>
        <div className="elecsun-title">
          <img src={logo} alt="electronic sun" />
        </div>
        <h2 onClick={props.selectTabHome}>home</h2>
      </nav>

      <div className="readMain">
        <div className="poemList">{formatPoemList()}</div>

        <button
          className={`pagePrevious${
            arrowsHidden && canScroll ? " arrowsHidden" : ""
          }`}
          disabled={pageNumber < 1}
          onClick={goToPreviousPages}
        >
          {"<"}
        </button>
        <div className="book">
          <div className="page">{pageA}</div>

          <div className="page">{pageB}</div>
          {pageNumber === 0 ? (
            <div className="arrowsinfo">
              <p>
                Use the arrows on the sides to go to the next/previous page.
              </p>
            </div>
          ) : null}
        </div>
        <button
          className={`pageNext${
            arrowsHidden && canScroll ? " arrowsHidden" : ""
          }`}
          disabled={pageNumber > 59}
          onClick={goToNextPages}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default Read;
